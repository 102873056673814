import React from 'react'

import ScrollVisibilityComponent from './../ScrollVisibilityComponent'
import LazyImageComponent from './../LazyImageComponent'

export const ThoughtSectionText = (props) => (
    <div className={`PageModule text margin-${props.data.top_margin_size}`}>
        <div className="col col8 col16-m">
            {props.data.media_type_left_column == 'image' ? 
                <div className="inner">
                    {props.data.image ? <LazyImageComponent height={props.data.image.height} width={props.data.image.width} url={props.data.image.sizes.fullscreen}/> : null}
                    {props.data.image_caption ? <div className="caption">{props.data.image_caption}</div> : null}
                </div>
            : props.data.media_type_left_column == 'embed' ? 
                <div className="inner">
                    <div className="embed" dangerouslySetInnerHTML={{__html: props.data.embed_code}}/>
                    {props.data.image_caption ? <div className="caption">{props.data.image_caption}</div> : null}
                </div>
            : props.data.media_type_left_column == 'image_url' ? 
                <div className="inner extimg">
                    <img src={props.data.image_url}/>
                    {props.data.image_caption ? <div className="caption">{props.data.image_caption}</div> : null}
                </div>
            : null}
        </div>
        <div className="col col8 col16-m single-text" dangerouslySetInnerHTML={{__html: props.data.text}}>
        </div>
    </div>
    )

export const ThoughtSectionQuote = (props) => (
    <div className="PageModule quote">
        <div className="col col8 col16-m"/>
        <div className="col col8 col16-m">
            <div className="text">{props.data.quote}</div>
            <div className="name">{props.data.name}</div>
            <div className="title">{props.data.title}</div>
        </div>
    </div>
    )