import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link, useRouteMatch} from 'react-router-dom'

import LazyImageComponent from './../../LazyImageComponent'
import {ReactComponent as CloseIcon} from './../../../../assets/icons/closeicon.svg'

function PeopleSingleModule(props) {
	let match = useRouteMatch();
	const [showMail, setShowMail] = useState(false)
	const [currentSlide, setCurrentSlide] = useState(false);
	const [layout, setLayout] = useState(1);
	
	useEffect(() => {
		if (match) {
			setShowMail(false)
			setLayout(Math.ceil(Math.random()*4))
		}
	},[match])

  return (
  	match ?
	  	<div className="PeopleSingleModule">
		  	<div className="inner">
		  		<div className={`col col7 images col16-l layout${layout}`}>
					{props.singleData.hoverImages ? props.singleData.hoverImages.map((ele, i) => (
						<div className={`wrap ${ele.height > ele.width ? 'vert' : 'hori'} ${i == currentSlide ? 'active' : ''}`} key={i} onClick={() => setCurrentSlide(i)}>
	    					<LazyImageComponent height={ele.height} width={ele.width} url={ele.sizes.large}/>
						</div>
						))
					: null}
					<div className={`wrap ${props.singleData.image.height > props.singleData.image.width ? 'vert' : 'hori'} ${currentSlide == props.singleData.hoverImages.length ? 'active' : ''}`} key={props.singleData.hoverImages.length} onClick={() => setCurrentSlide(props.singleData.hoverImages)}>
	    					<LazyImageComponent height={props.singleData.image.height} width={props.singleData.image.width} url={props.singleData.image.sizes.large}/>
					</div>
				</div>
				<div className="col col9 content col16-l">
					<h2 className="name">{props.singleData.post_title}</h2>
					<div className="data">{props.singleData.title}</div>
					<div className="data">{props.singleData.phone}</div>
					{props.singleData.email ? <div className="data email">
						{showMail ?	<a href={`mailto:${props.singleData.email}`}>{props.singleData.email}</a>
						: <span onMouseEnter={() => setShowMail(true)}>write me an email</span>}
						</div>
					: null}
					<div className="text" dangerouslySetInnerHTML={{__html: props.singleData.text}}/>
					{props.singleData.some ? 
						<ul className="some">
							{props.singleData.some.map((ele, i) => (
								<li key={i}><a href={ele.link} target="_blank">{ele.name}</a></li>
							))}
						</ul>
						: null
					}
				</div>
				<Link to={props.parentSlug}><CloseIcon/></Link>
		  	</div>
	  	</div>
  		: null
  );
}

export default connect((state, ownProps) => {
		const archivePostsAll = state.App.pageData[ownProps.parentSlug].data.archivePosts.posts
		return({singleData: archivePostsAll.find((ele) => ele.post_name == ownProps.slug)}
	)}
	)(PeopleSingleModule);
