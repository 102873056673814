import React, { useState } from 'react';
import { connect } from 'react-redux'
import './index.scss';

import { Link } from 'react-router-dom'



function HeaderTextModule(props) {
    const LinkContent = (ele) => {
        return (
            <span>
                {ele.text}
                {ele.images ? ele.images.map((img, imgI) => {
                    let orientation = img.width > img.height ? 'horizontal' : 'vertical';
                    return <img key={imgI} src={img.sizes.large} className={orientation} />
                }) : null}
            </span>
        )
    }

    return (
        props.data ?
            <div className={`PageModule HeaderTextModule mod${props.num}`}>
                <div className="col col16">
                    <div className="inner">
                        <div className="hero">
                            {props.data.layout == 'text' ? <p dangerouslySetInnerHTML={{ __html: props.data.text_left_big }} /> :
                                props.data.layout == 'text_with_hover_images' ?
                                    <p>
                                        {props.data.parts.map((ele, i) => (
                                            ele.acf_fc_layout == 'text' ? <span key={i}>{ele.text}</span>
                                                : ele.acf_fc_layout == 'highlight_text_with_images' ?
                                                    ele.link ?
                                                        <span className="collage" key={i}>
                                                            <Link to={'/' + ele.link.post_name}>
                                                                {LinkContent(ele)}
                                                            </Link>
                                                        </span>
                                                        :
                                                        <span className="collage" key={i}>
                                                            {LinkContent(ele)}
                                                        </span>
                                                    : null
                                        ))}
                                    </p>
                                    : null}
                        </div>
                        {props.data.manchet ?
                            <div className="manchet"><p dangerouslySetInnerHTML={{ __html: props.data.manchet }} /></div>
                            : null}
                    </div>
                </div>
            </div>
            : null
    );
}

export default connect()(HeaderTextModule);
