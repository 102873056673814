import React from 'react';
import {connect} from 'react-redux'
import './index.scss';
import {Link, Route, useRouteMatch} from 'react-router-dom'

import ScrollVisibilityComponent from './../../ScrollVisibilityComponent'
import PeopleSingleModule from './../PeopleSingleModule'
import PeopleThumb from './../PeopleThumb'

function PeopleSelectedModule(props) {
  const singleMatch = useRouteMatch(props.slug+"/:slug");

  return (
    <div className="PageModule PeopleSelectedModule">
    	<div className="col col16 title">
    		<div className="inner">
    			<span>{props.data.title}</span>
    			{props.data.link ? <Link to={props.data.link.post_name}>{props.data.link_label ? props.data.link_label : 'View full team'}</Link> : null}
			</div>
		</div>
		<div className="col col16 breaker">
    		<div className="inner"/>
		</div>
		{props.selectedPeople.map((ele, i) => (
			<PeopleThumb key={i} data={ele} slug={props.slug}/>
	 	))}
    </div>
  );
}

export default connect()(PeopleSelectedModule);
