import React, { useEffect, useState } from 'react';
import './index.scss';

import { getApiBase } from './../../helpers'
import { useParams, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import HeaderTextModule from './../PageModules/HeaderTextModule'
import CTASplitterModule from './../PageModules/CTASplitterModule'
import CTASplitterSmallModule from './../PageModules/CTASplitterSmallModule'
import CasesAllModule from './../PageModules/CasesAllModule'
import CasesSelectedModule from './../PageModules/CasesSelectedModule'
import PeopleAllModule from './../PageModules/PeopleAllModule'
import PeopleSelectedModule from './../PageModules/PeopleSelectedModule'
import TestimonialModule from './../PageModules/TestimonialModule'
import TextAndImageModule from './../PageModules/TextAndImageModule'
import NewsModule from './../PageModules/NewsModule'
import FaqModule from './../PageModules/FaqModule'
import WeKnowModule from './../PageModules/WeKnowModule'
import NewWeKnowModule from './../PageModules/NewModules/NewWeKnowModule'
import NewHeaderTextModule from './../PageModules/NewModules/NewHeaderTextModule'
import NewSeparatorLogo from './../PageModules/NewModules/NewSeparatorLogo'
import NewPopupMenu from './../PageModules/NewModules/NewPopupMenu'
import CodexModule from './../PageModules/CodexModule'
import PressModule from './../PageModules/PressModule'
import UntitledModule from './../PageModules/UntitledModule'
import ThoughtsAllModule from './../PageModules/ThoughtsAllModule'
import UploadPitchModule from './../PageModules/UploadPitchModule'
import ContactBigCTAButtonsModule from './../PageModules/ContactBigCTAButtonsModule'
import BreakerWithMultipleImages from './../PageModules/BreakerWithMultipleImages'
import PeopleStrategyAnimationModule from './../PageModules/PeopleStrategyAnimationModule'
import HubSpotForm from './../PageModules/HubSpotForm'
import Footer from './../Footer'
import Maintenance from '../PageModules/NewModules/Maintenance';

function NormalPage(props) {
  //console.log(props.apiData.pagemodules);
  useEffect(() => {
    if (props.routerAction === 'POP' && props.lastScroll) window.scrollTo(0, props.lastScroll.val)
    else window.scrollTo(0, 0);
  }, [props.dataKey])
  return (
    <div className={`page ${props.mainmenuIsOpen ? 'open' : ''}${props.dataKey == '/' ? 'frontpage' : props.dataKey == '/forside-placeholder' ? 'frontpage' : ''}`}>
      {props.pathname !== '/' && props.pathname == props.dataKey ?
        <Helmet>
          <title>PSV ∙ {props.apiData.title}</title>
          <meta name="description" content={ props.apiData && props.apiData.meta && props.apiData.meta.description ? props.apiData.meta.description : (props.meta && props.meta.description_global ? props.meta.description_global : "") } />
          <meta property="og:description" content={ props.apiData && props.apiData.meta && props.apiData.meta.description ? props.apiData.meta.description : (props.meta && props.meta.description_global ? props.meta.description_global : "") } />
          <meta property="og:image" content="https://psv.xyz/pressedbillede.jpg" />
        </Helmet>
        :
        <Helmet>
          <title>PSV</title>
          <meta name="description" content={ props.apiData && props.apiData.meta && props.apiData.meta.description ? props.apiData.meta.description : (props.meta && props.meta.description_global ? props.meta.description_global : "") } />
          <meta property="og:description" content={ props.apiData && props.apiData.meta && props.apiData.meta.description ? props.apiData.meta.description : (props.meta && props.meta.description_global ? props.meta.description_global : "") } />
          <meta property="og:image" content="https://psv.xyz/pressedbillede.jpg" />
        </Helmet>
      }


      {props.apiData.pagemodules ? props.apiData.pagemodules.map((ele, i) => (

        ele.acf_fc_layout === 'we_know_hero' ? <NewWeKnowModule key={i} data={ele} num={i} /> :
          ele.acf_fc_layout === 'header_text_new' ? <NewHeaderTextModule key={i} data={ele} num={i} /> :
            ele.acf_fc_layout === 'separator_logo_new' ? <NewSeparatorLogo key={i} data={ele} num={i} /> :
              ele.acf_fc_layout === 'popup_menu_new' ? <NewPopupMenu key={i} data={ele} slug={props.dataKey} num={i} rest={props} /> :
                ele.acf_fc_layout === 'cases_all' ? <CasesAllModule key={i} data={ele} archivePosts={props.apiData.archivePosts} slug={props.dataKey} num={i} /> :
                  ele.acf_fc_layout === 'cases_selected' ? <CasesSelectedModule key={i} data={props.apiData.selectedCases} viewAll={ele.view_all ? ele.view_all[0] : null} viewAllFilter={ele.view_all_filter} num={i} /> :
                    ele.acf_fc_layout === 'press' ? <PressModule key={i} data={ele} num={i} /> :
                      ele.acf_fc_layout === 'untitled_section' ? <UntitledModule key={i} data={ele} num={i} /> :
                        ele.acf_fc_layout === 'codex' ? <CodexModule key={i} data={ele} num={i} /> :
                          ele.acf_fc_layout === 'we_know' ? <WeKnowModule key={i} data={ele} num={i} /> :
                            ele.acf_fc_layout === 'thoughts_all' ? <ThoughtsAllModule key={i} data={ele} archivePosts={props.apiData.archivePosts} slug={props.dataKey} num={i} /> :
                              ele.acf_fc_layout === 'people_all' ? <PeopleAllModule key={i} data={ele} archivePosts={props.apiData.archivePosts} slug={props.dataKey} num={i} /> :
                                ele.acf_fc_layout === 'people_selected' ? <PeopleSelectedModule key={i} data={ele} selectedPeople={props.apiData.selectedPeople} num={i} /> :
                                  ele.acf_fc_layout === 'testimonial' ? <TestimonialModule key={i} data={ele} num={i} /> :
                                    ele.acf_fc_layout === 'header_text' ? <HeaderTextModule key={i} data={ele} num={i} /> :
                                      ele.acf_fc_layout === 'cta_splitter' ? <CTASplitterModule key={i} data={ele} num={i} /> :
                                        ele.acf_fc_layout === 'cta_splitter_small' ? <CTASplitterSmallModule key={i} data={ele} num={i} /> :
                                          ele.acf_fc_layout === 'text_and_image' ? <TextAndImageModule key={i} data={ele} num={i} /> :
                                            ele.acf_fc_layout === 'faq' ? <FaqModule key={i} data={ele} num={i} /> :
                                              ele.acf_fc_layout === 'news' ? <NewsModule key={i} data={ele} slug={props.dataKey} num={i} /> :
                                                ele.acf_fc_layout === 'upload_pitch_form' ? <UploadPitchModule key={i} data={ele} num={i} /> :
                                                  ele.acf_fc_layout === 'contact_big_cta_buttons' ? <ContactBigCTAButtonsModule key={i} data={ele} num={i} /> :
                                                    ele.acf_fc_layout === 'breaker_with_multiple_images' ? <BreakerWithMultipleImages key={i} data={ele} num={i} /> :
                                                      ele.acf_fc_layout === 'breaker_with_multiple_images' ? <BreakerWithMultipleImages key={i} data={ele} num={i} /> :
                                                        ele.acf_fc_layout === 'people_strategy_animation' ? <PeopleStrategyAnimationModule key={i} data={ele} num={i} /> :
                                                          ele.acf_fc_layout === 'hubspot_form' ? <HubSpotForm key={i} data={ele} num={i} /> :
                                                            /*  ele.acf_fc_layout === 'maintenance' ? <Maintenance key={i} data={ele} num={i} /> : */
                                                            null
      )) : null}
      <Footer />

    </div>
  );
}

export default connect((state, ownProps) => ({
  apiData: state.App.pageData[ownProps.dataKey].data,
  pathname: state.router.location.pathname,
  mainmenuIsOpen: state.Globals.mainmenuIsOpen,
  lastScroll: state.App.scrollhistory[state.router.location.key],
  routerAction: state.router.action,
  meta: state.Globals.meta
}))(NormalPage);
